import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import Categories from '../components/Categories';
import ItemList from '../components/ItemList';
import { css } from '@emotion/core';
import { LiveMessage } from 'react-aria-live';
import Helmet from 'react-helmet';

const indexStyle = theme => css`
  .index-container {
    margin-bottom: 5em;
  }

  .disclaimer {
    background-color: ${theme.cBlack};
    color: ${theme.cWhite};
    padding: 0.5em;
    font-style: italic;
    line-height: 1.5em;
    border-radius: 0.5em;
    box-shadow: 0 0.2em 1em rgba(59, 43, 91, 0.7);

    a {
      color: ${theme.cWhite};

      &:focus {
        outline: 2px solid ${theme.cWhite};
      }
    }
  }
`;

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const items = data.items.edges.map(({ node }) => ({
      title: node.title,
      slug: `${node.category.mainCategory.slug}/${node.category.slug}/${
        node.slug
      }`,
      thumbnail: node.thumbnails[0],
      datePublished: node.datePublished,
    }));
    const categories = data.categories.edges.map(({ node }) => ({
      title: node.title,
      slug: `/${node.slug}`,
      thumbnail: node.thumbnail,
    }));

    return (
      <Layout location={this.props.location}>
        <Helmet>
          <title>{`Vestiges of War | Home page`}</title>
        </Helmet>
        <LiveMessage message={`Navigated to home page`} aria-live="assertive" />
        <div css={indexStyle}>
          <p className="disclaimer">
            This website exists solely for historical, educational and research
            purposes and has no affiliation with any political parties, military
            organisations or religions. Please{' '}
            <Link to="/disclaimer">read our full disclaimer</Link> before
            proceeding.
          </p>
          <div className="index-container">
            <ItemList heading="Latest articles" items={items} />
          </div>
          <Categories heading="Article categories" categories={categories} />
        </div>
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    items: allDatoCmsItem(
      limit: 5
      sort: { fields: [datePublished], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          datePublished(formatString: "MMMM DD, YYYY")
          category {
            title
            slug
            mainCategory {
              title
              slug
            }
          }
          thumbnails: images {
            url
            fluid(maxWidth: 100, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    categories: allDatoCmsMainCategory {
      edges {
        node {
          id
          title
          slug
          thumbnail: image {
            url
            fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`;
